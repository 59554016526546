<template>
    <div class="container">
        <div class="content-page">
            <h1 class="title">Privacy Policy</h1>
            <h2 class="second-sub-title">Section 1 - What do we do with your information?</h2>
            <p class="paragraph">
                When you purchase something from our store, as part of the buying and selling process, we collect the personal information
                you give us such as your name, address and email address. When you browse our store, we also automatically receive your
                computer’s internet protocol (IP) address in order to provide us with information that helps us learn about your browser and
                operating system. Email marketing (if applicable): With your permission, we may send you emails about our store, new
                products and other updates.
            </p>
            <h2 class="second-sub-title">Section 2 - Consent</h2>
            <h4 class="third-sub-title">How do you get my consent?</h4>
            <p class="paragraph">
                When you provide us with personal information to complete a transaction, verify your credit card, place an order, arrange
                for a delivery or return a purchase, we imply that you consent to our collecting it and using it for that specific reason
                only. If we ask for your personal information for a secondary reason, like marketing, we will either ask you directly for
                your expressed consent, or provide you with an opportunity to say no
            </p>
            <h4 class="third-sub-title">How do I withdraw my consent?</h4>
            <p class="paragraph">
                If after you opt-in, you change your mind, you may withdraw your consent for us to contact you, for the continued
                collection, use or disclosure of your information, at anytime, by contacting us at
                <a href="mailto:contact@nemn.io">
                    <b>contact@nemn.io</b>
                </a>
                or mailing us at: NEM Group Limited. Suite 7, Hadfield House Library Street, Gibraltar.
            </p>
            <h2 class="second-sub-title">Section 3 - Disclosure</h2>
            <p class="paragraph">
                We may disclose your personal information if we are required by law to do so or if you violate our Terms of Service.
            </p>
            <h2 class="second-sub-title">Section 4 - Third-Party Services</h2>
            <p class="paragraph">
                In general, the third-party providers used by us will only collect, use and disclose your information to the extent
                necessary to allow them to perform the services they provide to us.
                <br />However, certain third-party service providers, such as payment gateways and other payment transaction processors,
                have their own privacy policies in respect to the information we are required to provide to them for your purchase-related
                transactions. <br />For these providers, we recommend that you read their privacy policies so you can understand the manner
                in which your personal information will be handled by these providers. <br />In particular, remember that certain providers
                may be located in or have facilities that are located a different jurisdiction than either you or us. So if you elect to
                proceed with a transaction that involves the services of a third-party service provider, then your information may become
                subject to the laws of the jurisdiction(s) in which that service provider or its facilities are located. Once you leave our
                store’s website or are redirected to a third-party website or application, you are no longer governed by this Privacy Policy
                or our website’s Terms of Service.
            </p>
            <h2 class="second-sub-title">Section 5 - Security</h2>
            <p class="paragraph">
                To protect your personal information, we take reasonable precautions and follow industry best practices to make sure it is
                not inappropriately lost, misused, accessed, disclosed, altered or destroyed.
            </p>
            <h2 class="second-sub-title">Section 6 - Cookies</h2>
            <p class="paragraph">
                We use cookies to maintain session of your user. It is not used to personally identify you on other websites.
            </p>
            <h2 class="second-sub-title">Section 7 - Age of Consent</h2>
            <p class="paragraph">
                By using this site, you represent that you are at least the age of majority in your state or province of residence, or that
                you are the age of majority in your state or province of residence and you have given us your consent to allow any of your
                minor dependents to use this site.
            </p>
            <h2 class="second-sub-title">Section 8 - Changes to this Privacy Policy</h2>
            <p class="paragraph">
                We reserve the right to modify this privacy policy at any time, so please review it frequently. Changes and clarifications
                will take effect immediately upon their posting on the website. If we make material changes to this policy, we will notify
                you here that it has been updated, so that you are aware of what information we collect, how we use it, and under what
                circumstances, if any, we use and/or disclose it.
                <br />If our store is acquired or merged with another company, your information may be transferred to the new owners so that
                we may continue to sell products to you.
            </p>
            <h2 class="second-sub-title">Questions and Contact Information</h2>
            <p class="paragraph">
                If you would like to: access, correct, amend or delete any personal information we have about you, register a complaint, or
                simply want more information contact our Privacy Compliance Officer at
                <a href="mailto:support@nem.group">
                    <b class="link">support@nem.group</b>
                </a>
                or by mail at NEM Group Limited. NEM Group Limited. Suite 7, Hadfield House Library Street, Gibraltar.
            </p>
        </div>
        <button @click="backToLogin">{{ $t('back') }}</button>
    </div>
</template>
<script>
import PrivacyPolicyTs from './PrivacyPolicyTs';
export default class PrivacyPolicy extends PrivacyPolicyTs {}
</script>

<style lang="less" scoped>
@import './PrivacyPolicy.less';
</style>
